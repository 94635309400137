<template>
  <div class="box">
    <div class="boxMain">
      <div style="font-weight: 600; font-size: 30px; text-align: center">
        联系我们
      </div>
      <div class="line"></div>
      <div class="mes" style="margin: 0 auto; width: 1200px">
        <div>联系人：<span>逄老师</span></div>
        <div>联系电话：<span>18615587553</span></div>
        <div>
          联系地址：<span>兰州市城关区白银路街道西北民族大学继续教育学院</span>
        </div>
        <div>联系邮箱：<span>xbmztl@masterol.cn</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  height: calc(100vh - 400px);
  background: #fff;
  padding: 30px 0px 64px;
}
.line {
  width: 100%;
  margin: 20px auto;
  border-top: 1px solid #eee;
}
.mes {
  > div {
  padding: 10px;
    
  }
}
.boxMain{
  padding: 20px;
  width: 1200px;
  height: calc(100vh - 500px);

  margin: 0 auto;
  box-shadow: 0px 4px 21px 0px rgba(122, 153, 195, 0.17);
}
</style>
